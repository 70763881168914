import $ from 'jquery'
import Swiper from 'swiper'
import Util from '../../bootstrap/js/src/util'
/**
 * --------------------------------------------------------------------------
 * ActiveElements (v1.0.0): advancedcarousel.js
 * --------------------------------------------------------------------------
 */
const AdvancedCarousel = (($) => {

  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */
  const NAME                = 'advancedcarousel'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'ae.advancedcarousel'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    direction: 'horizontal',
    loop: true,
    autoHeight: true,
    autoplay: false,
    autoplayDelay: 5000,
    parallax: false,
    keyboard: true,
    spaceBetween: 0,
    slidesPerView: 1,
    breakpoints: {},

    // components
    navigation: {
      active: false,
      hideOnClick: false
    },

    pagination: {
      active: false,
      type: 'bullets',
      clickable: true
    },

    scrollbar: {
      active: false,
      hide: true,
      draggable: false,
      snapOnRelease: false,
      dragSize: 'auto'
    }
  }

  const DefaultType = {
    direction: 'string',
    loop: 'boolean',
    autoHeight: 'boolean',
    autoplay: 'boolean',
    autoplayDelay: 'number',
    parallax: 'boolean',
    keyboard: 'boolean',
    spaceBetween: 'number',
    slidesPerView: 'number',
    breakpoints: 'object',

    // components
    navigation: {
      active: 'boolean',
      hideOnClick: 'boolean'
    },

    pagination: {
      active: 'boolean',
      type: 'string',
      clickable: 'boolean'
    },

    scrollbar: {
      active: 'boolean',
      hide: 'boolean',
      draggable: 'boolean',
      snapOnRelease: 'boolean',
      dragSize: 'string'
    }
  }

  const Selector = {
    DATA_RIDE: '[data-ride="advanced-carousel"]',
    NAVIGATION_PREV: '.swiper-button-next',
    NAVIGATION_NEXT: '.swiper-button-prev',
    PAGINATION: '.swiper-pagination',
    SCROLLBAR: '.swiper-scrollbar'
  }

  const Event = {
    LOAD_DATA_API  : `load${EVENT_KEY}${DATA_API_KEY}`
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */
  class AdvancedCarousel extends Swiper {

    constructor(element, config) {
      /* eslint constructor-super: 0 */

      super(element, {
        init: false
      })

      this._element = element
      this._config  = this._getConfig(config)

      this._buildNavigation()
      this._buildPagination()
      this._buildScrollbar()


      // eslint-disable-next-line no-console
      console.log(this._config)

      // this.init()
      super(element, this._config)
    }
    // getters
    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _buildNavigation() {
      if (this._config.navigation.active) {
        this._config = $.extend(true, this._config, {
          navigation: {
            nextEl: Selector.NAVIGATION_NEXT,
            prevEl: Selector.NAVIGATION_PREV
          }
        })


        $(this._element).append(`<div class="${Selector.NAVIGATION_PREV.substring(1)}"></div>`)
        $(this._element).append(`<div class="${Selector.NAVIGATION_NEXT.substring(1)}"></div>`)
      }
    }

    _buildPagination() {
      // eslint-disable-next-line no-console
      if (this._config.pagination.active) {
        this._config = $.extend(true, this._config, {
          pagination: {
            el: Selector.PAGINATION
          }
        })

        $(this._element).append(`<div class="${Selector.PAGINATION.substring(1)}"></div>`)
      }
    }

    _buildScrollbar() {
      if (this._config.scrollbar.active) {
        this._config = $.extend(true, this._config, {
          scrollbar: {
            el: Selector.SCROLLBAR
          }
        })
        $(this._element).append(`<div class="${Selector.SCROLLBAR.substring(1)}"></div>`)
      }
    }

    _getConfig(config) {
      config = $.extend(true, {}, Default, config)

      for (const property in config) {
        if (property.indexOf('_') > 0) {
          const splittedProperty = property.split('_', 1 + 1)
          config[splittedProperty[0]][splittedProperty[1]] = config[property]
          delete config[property]
        }
      }

      this._typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _typeCheckConfig(componentName, config, configTypes) {
      for (const property in configTypes) {
        if (Object.prototype.hasOwnProperty.call(configTypes, property)) {
          const expectedTypes = configTypes[property]
          const value         = config[property]

          if (typeof value === 'object' && typeof expectedTypes === 'object') {
            this._typeCheckConfig(NAME, value, expectedTypes)
          } else {
            const valueType     = value && Util.isElement(value)
              ? 'element' : this._toType(value)

            if (!new RegExp(expectedTypes).test(valueType)) {
              throw new Error(
                `${componentName.toUpperCase()}: ` +
                `Option "${property}" provided type "${valueType}" ` +
                `but expected type "${expectedTypes}".`)
            }
          }
        }
      }
    }

    _toType(obj) {
      return {}.toString.call(obj).match(/\s([a-z]+)/i)[1].toLowerCase()
    }

    // static
    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config  = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new AdvancedCarousel(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.DATA_RIDE).each(function () {
      const $advancedCarousel = $(this)
      AdvancedCarousel._jQueryInterface.call($advancedCarousel, $advancedCarousel.data())
    })
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */
  $.fn[NAME]             = AdvancedCarousel._jQueryInterface
  $.fn[NAME].Constructor = AdvancedCarousel
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return AdvancedCarousel._jQueryInterface
  }

  return AdvancedCarousel

})($)

export default AdvancedCarousel
